import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import DraggableCard from '../DraggableCard';
import cardBack from '../../../public/assets/images/static/card_back.png';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePanel, setCurrentIndex } from '../../store/mainReducer';
import DraggableTraining from '../DraggableTraining/DraggableTraining';
import cardTraining from '../../../public/assets/images/static/training-card.png';


const Training = ({}) => {
    const dispatch = useDispatch();
    const [classPage, setClassPage] = useState('main2');
    const cards = [cardTraining];
    const [firstTraining, setFirstTraining] = useState(false);
    const [tutorialActive, setTutorialActive] = useState(true);
    const [isLeft, setIsLeft] = useState(false);
    function continueHandler() {
      setFirstTraining(true);
      setTutorialActive(false);
    }
    function startGame() {
      setClassPage("main2");
        setTimeout(() => {
          dispatch(setActivePanel('main'));
        }, 500)
    }
    useEffect(() => {
      setTimeout(() => {
        setClassPage('main2 main2_active');
      }, 100)
    }, []);
    return (
        <>
          <div className={`container ${classPage}`}>
            <div className='main2__content'>
              <p style={{display: firstTraining ? 'none' : 'block'}} className='training__top-text'>
                Просто свайпайте:
              </p>
              <div style={{display: firstTraining ? 'none' : 'flex'}} className='training__arrows-block'>
                <img style={{opacity: isLeft ? '1' : '.2'}} className='training__arrow training__arrow_left' src="assets/images/left-arrow.svg" alt="" />
                <img style={{opacity: isLeft ? '.2' : '1'}} className='training__arrow training__arrow_right' src="assets/images/training-right-arrow.svg" alt="" />
              </div>
              <p style={{display: firstTraining ? 'none' : 'block'}} className='training__bottom-text'>
                <span className='training__bottom-bold-text'>{isLeft ? 'Влево, ' : 'Вправо '}</span>{isLeft ? 'если не хотите делать задачу.' : '— если готовы браться за дело.'} 
              </p>
              <p style={{display: firstTraining ? 'block' : 'none'}} className='training__start-text'>
                Но&nbsp;помните: каждое решение влияет на&nbsp;деньги, репутацию и&nbsp;энергию. 
                Удержать баланс&nbsp;&mdash; вот главный вызов.
              </p>
              <button style={{display: firstTraining ? 'none' : 'block'}} onClick={continueHandler} className='training__button' type='button'>
                <p className='training__button-text'>Продолжить</p>
                <div className='training__button-help'></div>
              </button>
              <button style={{display: firstTraining ? 'block' : 'none'}} onClick={startGame} className='training__button' type='button'>
                <p className='training__button-text'>Начать игру</p>
                <div className='training__button-help'></div>
              </button>
              <div className='training'>
                
              </div>
              <img className='main2__logo' src="assets/images/loading-page-logo.svg" alt="" />
              <div className='main2__basic'>
                <div className='main2__topbar topbar2'>
                    <div style={{zIndex: firstTraining ? '11' : '0', backgroundColor: firstTraining ? '#FFF' : 'rgba(0, 0, 0, 0.04)'}} className='topbar2__item'>
                      <div style={{width: `15px`, backgroundColor: 'rgba(255, 96, 0, 1)'}} className='topbar2__item-circle'></div>
                      <img className='topbar2__item-img' src="assets/images/topbar-money.svg" alt="" />
                      <img style={{height: `30px`}} className='topbar2__item-img-full' src="assets/images/topbar-money-full.svg" alt="" />
                    </div>
                    <div style={{zIndex: firstTraining ? '11' : '0', backgroundColor: firstTraining ? '#FFF' : 'rgba(0, 0, 0, 0.04)'}} className='topbar2__item'>
                      <div style={{width: `0px`, backgroundColor: 'green'}} className='topbar2__item-circle'></div>
                      <img className='topbar2__item-img' src="assets/images/topbar-coin.svg" alt="" />
                      <img style={{height: `7px`}} className='topbar2__item-img-full' src="assets/images/topbar-coin-full.svg" alt="" />
                    </div>
                    <div style={{zIndex: firstTraining ? '11' : '0', backgroundColor: firstTraining ? '#FFF' : 'rgba(0, 0, 0, 0.04)'}} className='topbar2__item'>
                      <div style={{width: `7px`, backgroundColor: 'green'}} className='topbar2__item-circle'></div>
                      <img className='topbar2__item-img' src="assets/images/topbar-energy.svg" alt="" />
                      <img style={{height: `22px`}} className='topbar2__item-img-full' src="assets/images/topbar-energy-full.svg" alt="" />
                    </div>
                  <div style={{zIndex: firstTraining ? '11' : '0', color: firstTraining ? '#FFF' : '#000'}} className='topbar2__count'>
                    1/15
                  </div>
                </div>
                <p className='main2__card-text'>
                  Lorem ipsum dolor sit amet consectetur. Ut sollicitudin sed tristique porttitor ullamcorper. Justo fermentum viverra ac morbi bibendum. 
                </p>
                <div style={{zIndex: firstTraining ? '0' : '11', pointerEvents: 'none'}} className="wrapper">
                  <DraggableTraining
                    setIsLeft={setIsLeft}
                    tutorialActive={tutorialActive}
                    setTutorialActive={setTutorialActive}
                    cards={cards}
                    backSideImage={cardBack}
                    />
                </div>
              </div>
            </div>
          </div>
        </>
    );
}

export default Training;
import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { setActivePanel } from "../../store/mainReducer";
import bridge from '@vkontakte/vk-bridge';
import './style.css'
import ServerConnect from '../../service';


const Loading = ({}) => {
    const dispatch = useDispatch();
    const [classPage, setClassPage] = useState("loading-page")

    function buttonHandler() {
      setClassPage("loading-page loading-page_disable");
      setTimeout(() => {
        dispatch(setActivePanel("onboarding-first"));
      }, 500)
    }
    return (
        <div className={`container ${classPage}`}>
            <div className='loading-page__content'>
              <img className='loading-page__logo' src="assets/images/loading-page-logo.svg" alt="" />
              <div className='loading-page__main'>
                <img className='loading-page__cloud loading-page__cloud_left' src="assets/images/loading-page-left-cloud.svg" alt="" />
                <img className='loading-page__cloud loading-page__cloud_right' src="assets/images/loading-page-right-cloud.svg" alt="" />
                <img className='loading-page__coin' src="assets/images/loading-page-img.png" alt="" />
                <p className='loading-page__title'>
                  Привет! Это команда героев аутсорсинга и&nbsp;автоматизации UCMS&nbsp;Group.
                </p>
                <p className='loading-page__subtitle'>
                  Мы&nbsp;знаем, как много всего нужно успеть за&nbsp;день. А&nbsp;ещё знаем, что иногда хочется забить на&nbsp;всё. В&nbsp;нашей игре у&nbsp;вас есть такая возможность!
                </p>
                <button onClick={buttonHandler} className='loading-page__button' type='button'>
                  <p className='loading-page__button-text'>Продолжить</p>
                  <div className='loading-page__button-help'></div>
                </button>
              </div>
            </div>
        </div>
    );
}


export default Loading;
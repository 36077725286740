import React,{ useEffect, useRef, useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import Main from './components/Main';
import Loading from './components/Loading';

import Overlay from './panels/Overlay';
import bridge from '@vkontakte/vk-bridge';

import { setLaunchParams,setCurrentIndex , setCardsLength, setPlatform, setAttempts, setIncrement, setActiveModal, setModalName, setConfirmAll, setFirstPage, setGroupLink, setTickets, setHeals, setTasks, setTgID, setPrizes, setGameDays, setReferal, setFriendsCount, setActivePanel, setSessionId, setCrfToken, setStartData } from "./store/mainReducer"

import ServerConnect from './service';

import { Snackbar } from "@vkontakte/vkui";
import { Icon24DismissSubstract, Icon24CheckCircleOn } from '@vkontakte/icons';
import '@vkontakte/vkui/dist/vkui.css';
import { isIOS } from 'react-device-detect';
import OnBoardingFirst from './components/OnBoardingFirst';
import Energy from './components/Energy';
import Money from './components/Money';
import Reputation from './components/Reputation';
import Final from './components/Final';
import Training from './components/Training';
import PseudoFinal from './components/PseudoFinal';

const App = () => {
  const dispatch = useDispatch();
  const activePanel = useSelector(state => state.main.activePanel);
  const platform = useSelector(state => state.main.platform);

  useEffect(() => {
    // dispatch(setActivePanel('loading'));
    if (localStorage.getItem('session_id')) {
        dispatch(setSessionId(localStorage.getItem('session_id')));
        if (localStorage.getItem('final') == 'true' || localStorage.getItem('money') == 'true'|| localStorage.getItem('energy') == 'true' || localStorage.getItem('reputation') == 'true' || localStorage.getItem('pseudofinal') == 'true') {
          if (localStorage.getItem('final') == 'true') {
            dispatch(setActivePanel('final'))
          }
          else if (localStorage.getItem('money') == 'true') {
            dispatch(setActivePanel('money'))
          }
          else if (localStorage.getItem('energy') == 'true') {
            dispatch(setActivePanel('energy'))
          }
          else if (localStorage.getItem('reputation') == 'true') {
            dispatch(setActivePanel('reputation'))
          }
          else if (localStorage.getItem('pseudofinal') == 'true') {
            dispatch(setActivePanel('pseudofinal'))
          }
        }
        else {
          ServerConnect.getContinue({'session_id': localStorage.getItem('session_id')})
          .then((data) => {
            console.log(data);
            if (data.details) {
              if (data.details === 'User Game not exists') {
                ServerConnect.startGame({
                  'clear_start': false,
                  'session_id': localStorage.getItem('session_id'),
                })
                  .then((data) => {
                    console.log(data.data);
                    localStorage.setItem('energy', false);
                    localStorage.setItem('money', false);
                    localStorage.setItem('reputation', false);
                    localStorage.setItem('final', false);
                    dispatch(setStartData(data.data));
                    dispatch(setCardsLength(data.data.user_cards.length));
                    dispatch(setCurrentIndex(0));
                    dispatch(setActivePanel('main'));
                  })
                  .catch(err => console.log(err));
              }
              else if (data.details === "User Session not exists") {
                dispatch(setActivePanel('loading'));
              }
            }
            else {
              const targetCardId = data.data.cur_stats.current_cart_id;
              console.log(targetCardId);
              const targetIndex = data.data.user_cards.findIndex(card => card.card_id.toString() === targetCardId.toString());
              console.log(targetIndex);
              dispatch(setCardsLength(data.data.user_cards.length));
              dispatch(setCurrentIndex(targetIndex));
              const slicedCards = data.data.user_cards.slice(targetIndex);
              console.log(slicedCards);
              dispatch(setStartData({
                "cur_stats": data.data.cur_stats,
                "user_cards": slicedCards,
              }));
              dispatch(setActivePanel('main'));
            }
          })
          .catch((error) => {console.log(error);});
        }
    }
    else {
      dispatch(setActivePanel('loading'));
    }
  }, []);

  let content = []
  if (activePanel === 'loading') {
    content.push(<Loading key='loading'/>)
  }
  else if (activePanel === 'onboarding-first') {
    content.push(<OnBoardingFirst key='onboarding-first'/>)
  }
  else if (activePanel === 'main') {
    content.push(<Main key='main'/>)
  }
  else if (activePanel === 'energy') {
    content.push(<Energy key='energy'/>)
  }
  else if (activePanel === 'money') {
    content.push(<Money key='money'/>)
  }
  else if (activePanel === 'reputation') {
    content.push(<Reputation key='reputation'/>)
  }
  else if (activePanel === 'final') {
    content.push(<Final key='final'/>)
  }
  else if (activePanel === 'training') {
    content.push(<Training key='training'/>)
  }
  else if (activePanel === 'pseudofinal') {
    content.push(<PseudoFinal key='pseudofinal'/>)
  }
  return (
    <div className={`app ${platform}`}>
      <>
        <img className='main-help-background' src="assets/images/main-help-bacground.png" alt="" />
        <img className='main-background' src="assets/images/bacground.svg" alt="" />
        <div className={isIOS ? 'ios-help-bg' : 'not-ios-help'}></div>
        {content}
      </>
    </div>
  );
}
export default App;

import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import DraggableCard from '../DraggableCard';
import img1 from '../../../public/assets/images/static/card-1.png';
import img2 from '../../../public/assets/images/static/card-2.png';
import img3 from '../../../public/assets/images/static/card-3.png';
import img4 from '../../../public/assets/images/static/card-4.png';
import cardBack from '../../../public/assets/images/static/card_back.png';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePanel, setCurrentIndex } from '../../store/mainReducer';


const Main = ({}) => {
    const dispatch = useDispatch();
    const startData = useSelector(state => state.main.startData);
    const sessionId = useSelector(state => state.main.sessionId);
    const cardsLength = useSelector(state => state.main.cardsLength);
    const currentStateIndex = useSelector(state => state.main.currentIndex);
    const [delta, setDelta] = useState({
      'money': {
        'if_yes': 0,
        'if_no': 0
      },
      'energy': {
        'if_yes': 0,
        'if_no': 0
      },
      'some_stuff': {
        'if_yes': 0,
        'if_no': 0
      }
    });
    const [widthCircle, setWidthCircle] = useState({
      'money': {
        'width': 0,
        'background-color': ''
      },
      'energy': {
        'width': 0,
        'background-color': ''
      },
      'some_stuff': {
        'width': 0,
        'background-color': ''
      },
    });
    console.log(startData)
    console.log(sessionId)
    const [classPage, setClassPage] = useState('main');
    const fullStats = {
      'money': startData.cur_stats.max_money,
      'some_stuff': startData.cur_stats.max_some_stuff,
      'energy': startData.cur_stats.max_energy,
    };
    const [currentStats, setCurrentStats] = useState({
      'money': startData.cur_stats.money,
      'some_stuff': startData.cur_stats.some_stuff,
      'energy': startData.cur_stats.energy,
    });
    function getWidthCircle(name) {
      return widthCircle[name].width / fullStats[name] * 100;
    }
    function getHeightStats(currentStat, name) {
      return currentStat / fullStats[name] * 37;
    }
    useEffect(() => {
      setTimeout(() => {
        setClassPage('main main_active');
      }, 100)
    }, []);

    const cardsData = startData.user_cards;
    const [cards, setCards] = useState(cardsData);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
  
    useEffect(() => {
      const preloadImages = () => {
        cards.forEach(card => {
          const img = new Image();
          img.src = 'https://reignsconst.despbots.ru' + card.img_url;
        });
      };
      setCards(cards);
      setCurrentCardIndex(0);
      
      preloadImages();
    }, [startData]);
  
    const handleCardChange = (nextCardId) => {
      setCurrentCardIndex(nextCardId);
      dispatch(setCurrentIndex(currentStateIndex + 1));
      console.log(nextCardId)
    };

    const currentCard = cards.find((card, index) => index === currentCardIndex);
    useEffect(() => {
      console.log(currentCard)
      if (currentCard) {
        setDelta({
          'money': {
            'if_yes': currentCard.if_yes.money,
            'if_no': currentCard.if_no.money
          },
          'energy': {
            'if_yes': currentCard.if_yes.energy,
            'if_no': currentCard.if_no.energy
          },
          'some_stuff': {
            'if_yes': currentCard.if_yes.some_stuff,
            'if_no': currentCard.if_no.some_stuff
          }
        })
      }
      if (!currentCard) {
        console.log('end')
      }
    }, [currentCard, startData])
    useEffect(() => {
      console.log(currentStats)
      if (currentStats.energy == 0) {
        dispatch(setActivePanel('energy'));
        localStorage.setItem('energy', true);
        localStorage.setItem('money', false);
        localStorage.setItem('reputation', false);
        localStorage.setItem('final', false);
      }
      if (currentStats.money == 0) {
        dispatch(setActivePanel('money'))
        localStorage.setItem('energy', false);
        localStorage.setItem('money', true);
        localStorage.setItem('reputation', false);
        localStorage.setItem('final', false);
      }
      if (currentStats.some_stuff == 0) {
        dispatch(setActivePanel('reputation'));
        localStorage.setItem('energy', false);
        localStorage.setItem('money', false);
        localStorage.setItem('reputation', true);
        localStorage.setItem('final', false);
      }
    }, [currentStats, startData])
    const wrapperRef = useRef(null);
    useEffect(() => {
      if (wrapperRef.current) {
        wrapperRef.current.style.height = `${wrapperRef.current.offsetWidth}px`;
      }
    }, [])
    return (
        <>
          <div className={`container ${classPage}`}>
            <div className='main__content'>
              <img className='main__logo' src="assets/images/loading-page-logo.svg" alt="" />
              <div className='main__basic'>
                <div className='main__topbar topbar'>
                    <div className='topbar__item'>
                      <div style={{width: `${getWidthCircle('money')}px`, backgroundColor: widthCircle.money['background-color']}} className='topbar__item-circle'></div>
                      <img className='topbar__item-img' src="assets/images/topbar-money.svg" alt="" />
                      <img style={{height: `${getHeightStats(currentStats.money, 'money')}px`}} className='topbar__item-img-full' src="assets/images/topbar-money-full.svg" alt="" />
                    </div>
                    <div className='topbar__item'>
                      <div style={{width: `${getWidthCircle('some_stuff')}px`, backgroundColor: widthCircle.some_stuff['background-color']}} className='topbar__item-circle'></div>
                      <img className='topbar__item-img' src="assets/images/topbar-coin.svg" alt="" />
                      <img style={{height: `${getHeightStats(currentStats.some_stuff, 'some_stuff')}px`}} className='topbar__item-img-full' src="assets/images/topbar-coin-full.svg" alt="" />
                    </div>
                    <div className='topbar__item'>
                      <div style={{width: `${getWidthCircle('energy')}px`, backgroundColor: widthCircle.energy['background-color']}} className='topbar__item-circle'></div>
                      <img className='topbar__item-img' src="assets/images/topbar-energy.svg" alt="" />
                      <img style={{height: `${getHeightStats(currentStats.energy, 'energy')}px`}} className='topbar__item-img-full' src="assets/images/topbar-energy-full.svg" alt="" />
                    </div>
                  <div className='topbar__count'>
                    {currentCardIndex >= cardsLength ? cardsLength : currentStateIndex + 1}/{cardsLength}
                  </div>
                </div>
                {currentCard && <p className='main__card-text'>{currentCard.card_text}</p>}
                  <div ref={wrapperRef} className="wrapper">
                  {currentCard && (
                    <DraggableCard
                      cards={cards}
                      currentCardIndex={currentCardIndex}
                      onCardChange={handleCardChange}
                      backSideImage={cardBack}
                      setCurrentStats={setCurrentStats}
                      currentStats = {currentStats}
                      fullStats={fullStats}
                      delta={delta}
                      setWidthCircle={setWidthCircle}
                      />
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
    );
}

export default Main;
import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePanel, setStartData, setCardsLength, setCurrentIndex } from '../../store/mainReducer';
import ServerConnect from '../../service';

const Money = () => {
  const dispatch = useDispatch();
  const [classPage, setClassPage] = useState('money');
  const sessionId = useSelector(state => state.main.sessionId);
  function buttonHandler() {
    ServerConnect.startGame({
      'clear_start': false,
      'session_id': sessionId,
      'mailing_agreed': false,
    })
      .then((data) => {
        console.log(data.data);
        localStorage.setItem('energy', false);
        localStorage.setItem('money', false);
        localStorage.setItem('reputation', false);
        localStorage.setItem('final', false);
        localStorage.setItem('pseudofinal', false);
        dispatch(setStartData(data.data));
        setClassPage('money');
        dispatch(setCardsLength(data.data.user_cards.length));
        dispatch(setCurrentIndex(0));
        setTimeout(() => {
          dispatch(setActivePanel("main"));
        }, 500)
      })
      .catch(err => console.log(err));
  }
  useEffect(() => {
    setTimeout(() => {
      setClassPage('money money_active');
    }, 100)
  }, []);
  const bottomRef = useRef(null);

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 100)
  }, [])
  return (
    <div className={`container ${classPage}`}>
      <div className='money__content'>
        <img className='money__img' src="assets/images/money-big.png" alt="" />
        <p className='money__title'>
          Кажется, пора 
          пересмотреть бюджет...
        </p>
        <p className='money__subtitle'>
          Хотя пересматривать уже и&nbsp;нечего...
          Возможно, иногда стоит потратить немного денег на&nbsp;улучшение процессов 
          и&nbsp;это сэкономит бюджет в&nbsp;будущем.
        </p>
        <button type='button' className='money__info-btn'>
          <a className='money__info-btn-link' href="https://www.ucmsgroup.ru/" target='_blank'></a>
          Узнать больше про UCMS Group
        </button>
        <button onClick={buttonHandler} className='money__button' type='button'>
          <img className='money__button-img' src="assets/images/recycle.svg" alt="" />
          <p className='money__button-text'>
            Перезапустить игру
          </p>
        </button>
        <a ref={bottomRef} className='money__sticker' href="" target='_blank'>
          Стикерпак в подарок
        </a>
      </div>
    </div>
  );
};

export default Money;
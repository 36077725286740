import {createSlice} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name: 'main',
    initialState: {
        platform: null,
        activePanel: '',
        cardInfo: {
        },
        launch_params: {
        },
        isLoading: false,
        activeModal: false,
        modalName: '',
        startData: {},
        sessionId: '',
        crfToken: '',
        cardsLength: 0,
        currentIndex: 0,
    },
    reducers: {
        setLaunchParams(state, action) {
            state.launch_params = action.payload
        },
        setActivePanel(state, action) {
            state.activePanel = action.payload
        },
        setActiveModal(state, action) {
            state.activeModal = action.payload
        },
        setModalName(state, action) {
            state.modalName = action.payload;
        },
        setStartData(state, action) {
            state.startData = action.payload;
        },
        setSessionId(state, action) {
            state.sessionId = action.payload;
        },
        setCrfToken(state, action) {
            state.crfToken = action.payload;
        },
        setCardsLength(state, action) {
            state.cardsLength = action.payload;
        },
        setCurrentIndex(state, action) {
            state.currentIndex = action.payload;
        }
    }
})

export default mainSlice.reducer
export const {
    setActivePanel,
    setLaunchParams,
    setActiveModal,
    setModalName,
    setStartData,
    setSessionId,
    setCrfToken,
    setCardsLength,
    setCurrentIndex,
} = mainSlice.actions
// import apiUrl from './config.js';
const api_url = "https://reignsconst.despbots.ru/";
// const api_url = apiUrl;
import {URLSearchParams} from "core-js"

const front_url = 'https://vk-billion.gamesmm.ru'
const params = window.location.search;

async function startGame(data = {}, crfToken) {
    let request = api_url + 'start_game/';

    let response = await fetch(request,
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': crfToken
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
    if (!response.ok) {
        throw new Error(`Неполучилось отправить POST запрос ${url}, статус ${response.status}`)
    }
    return await response.json()
}

async function nextScreen(data = {}, crfToken) {
    let request = api_url + 'next_screen/';

    let response = await fetch(request,
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': crfToken
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data)
        });
    // if (!response.ok) {
    //     throw new Error(`Неполучилось отправить POST запрос ${url}, статус ${response.status}`)
    // }
    return await response.json()
}
async function getCRF() {
    let request = api_url + 'get_csrf_token/';
    let response = await fetch(request,
        {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
        });
    if (!response.ok) {
        throw new Error(`Неполучилось отправить POST запрос ${url}, статус ${response.status}`)
    }
    return await response.json()
}
async function getContinue(data = {}) {
    let request = api_url + 'continue_game/';
    let response = await fetch(request,
        {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            },
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: JSON.stringify(data),
        });
    // if (!response.ok) {
    //     throw new Error(`Неполучилось отправить POST запрос ${url}, статус ${response.status}`)
    // }
    return await response.json()
}

const ServerConnect = {
    startGame: startGame,
    nextScreen: nextScreen,
    getCRF: getCRF,
    getContinue: getContinue,
    api_url: api_url,
    front_url: front_url
}

export default ServerConnect;

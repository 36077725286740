import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePanel, setStartData, setCardsLength, setCurrentIndex } from '../../store/mainReducer';
import ServerConnect from '../../service';

const Reputation = () => {
  const dispatch = useDispatch();
  const [classPage, setClassPage] = useState('reputation');
  const sessionId = useSelector(state => state.main.sessionId);
  function buttonHandler() {
    ServerConnect.startGame({
      'clear_start': false,
      'session_id': sessionId,
      'mailing_agreed': false,
    })
      .then((data) => {
        localStorage.setItem('energy', false);
        localStorage.setItem('money', false);
        localStorage.setItem('reputation', false);
        localStorage.setItem('final', false);
        localStorage.setItem('pseudofinal', false);
        console.log(data.data);
        dispatch(setStartData(data.data));
        setClassPage('reputation');
        dispatch(setCardsLength(data.data.user_cards.length));
        dispatch(setCurrentIndex(0));
        setTimeout(() => {
          dispatch(setActivePanel("main"));
        }, 500)
      })
      .catch(err => console.log(err));
  }
  useEffect(() => {
    setTimeout(() => {
      setClassPage('reputation reputation_active');
    }, 100)
  }, []);
  const bottomRef = useRef(null);

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 100)
  }, [])
  return (
    <div className={`container ${classPage}`}>
      <div className='reputation__content'>
        <img className='reputation__img' src="assets/images/reputation-big.png" alt="" />
        <p className='reputation__title'>
          Ой, репутация сдулась!
        </p>
        <p className='reputation__subtitle'>
          Ой, репутация сдулась! Но&nbsp;не&nbsp;обязательно сразу рисовать в&nbsp;голове печальный образ, всегда есть шанс всё поправить. 
          Ни&nbsp;на&nbsp;что не&nbsp;намекаем, но&nbsp;репутацию часто повышает автоматизация рутинных процессов.
        </p>
        <button type='button' className='reputation__info-btn'>
          <a className='reputation__info-btn-link' href="https://www.ucmsgroup.ru/" target='_blank'></a>
          Узнать больше про UCMS Group
        </button>
        <button onClick={buttonHandler} className='reputation__button' type='button'>
          <img className='reputation__button-img' src="assets/images/recycle.svg" alt="" />
          <p className='reputation__button-text'>
            Перезапустить игру
          </p>
        </button>
        <a ref={bottomRef} className='reputation__sticker' href="" target='_blank'>
          Стикерпак в подарок
        </a>
      </div>
    </div>
  );
};

export default Reputation;
import React, { useState, useEffect, useRef } from 'react';
import { DraggableCore } from 'react-draggable';
import './style.css';
import ServerConnect from '../../service';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePanel, setCardsLength, setCurrentIndex, setStartData } from '../../store/mainReducer';

const DraggableCard = ({ cards, currentCardIndex, onCardChange, backSideImage, setCurrentStats, currentStats, fullStats, delta, setWidthCircle }) => {
  const dispatch = useDispatch();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [rotateY, setRotateY] = useState(0);
  const [rotateZ, setRotateZ] = useState(0);
  const [isHidden, setIsHidden] = useState(false);
  const [tutorialActive, setTutorialActive] = useState(false);
  const [animationClass, setAnimationClass] = useState('');
  const [flipClass, setFlipClass] = useState('');
  const [nextCard, setNextCard] = useState(null);
  const targetPositionRef = useRef({ x: 0, y: 0 });
  const timeoutsRef = useRef([]);
  const draggableRef = useRef(null);

  const currentCard = cards.find((card, index) => index === currentCardIndex);
  const nextCardData = nextCard !== null ? cards.find((card, index) => index === nextCard) : null;

  const sessionId = useSelector(state => state.main.sessionId);

  const animationDurationX = 0.5;
  const animationDurationZ = 0.15;
  const epsilon = 0.01;

  const clearAllTimeouts = () => {
    timeoutsRef.current.forEach(clearTimeout);
    timeoutsRef.current = [];
  };

  useEffect(() => {
    if (!tutorialActive) return;
    if (currentCardIndex > 1) return;

    const runTutorialAnimation = () => {
      setAnimationClass('animate-left');
      targetPositionRef.current = { x: -80, y: 0 };
      const timeout1 = setTimeout(() => {
        setAnimationClass('animate-right');
        targetPositionRef.current = { x: 80, y: 0 };
        const timeout2 = setTimeout(() => {
          setAnimationClass('animate-center');
          targetPositionRef.current = { x: 0, y: 0 };
          // const timeout3 = setTimeout(runTutorialAnimation, 5000);
          const timeout3 = setTimeout(runTutorialAnimation, 500);
          timeoutsRef.current.push(timeout3);
        }, 1000);
        timeoutsRef.current.push(timeout2);
      }, 1000);
      timeoutsRef.current.push(timeout1);
    };

    const startTimeout = setTimeout(runTutorialAnimation, 1500);
    timeoutsRef.current.push(startTimeout);

    return () => {
      clearAllTimeouts();
      setAnimationClass('');
    };
  }, [currentCardIndex, tutorialActive]);

  useEffect(() => {
    if (!currentCard) return;

    let animationFrameId;
    const animate = () => {
      setPosition(prevPosition => {
        const targetPosition = targetPositionRef.current;
        const dx = targetPosition.x - prevPosition.x;
        if (Math.abs(dx) < epsilon) return prevPosition;
        const newX = prevPosition.x + dx * animationDurationX;
        const newRotateY = newX / 10;
        setRotateY(newRotateY);
        return { x: newX, y: 0 };
      });

      setRotateZ(prevRotateZ => {
        const targetRotateZ = position.x / 20;
        const dz = targetRotateZ - prevRotateZ;
        if (Math.abs(dz) < epsilon) return prevRotateZ;
        return prevRotateZ + dz * animationDurationZ;
      });

      animationFrameId = requestAnimationFrame(animate);
    };
    animationFrameId = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrameId);
  }, [position.x, animationDurationX, animationDurationZ, currentCard]);

  const [prevPositionX, setPrevPositionX] = useState(0);
  const [hasLoggedPositive, setHasLoggedPositive] = useState(false);
  const [hasLoggedNegative, setHasLoggedNegative] = useState(false);

  const handleDrag = (e, data) => {
    if (!currentCard) return;
    clearAllTimeouts();
    setAnimationClass('');
    setTutorialActive(false);
    const newX = Math.min(100, Math.max(-100, targetPositionRef.current.x + data.deltaX));
    targetPositionRef.current = { x: newX, y: 0 };

    // Проверяем, действительно ли произошла смена знака position.x
    if ((prevPositionX < 0 && newX >= 0) || (prevPositionX === 0 && newX >= 0)) {
      if (!hasLoggedPositive) {
        console.log('yes');
        setHasLoggedPositive(true);
        setHasLoggedNegative(false);
        setWidthCircle({
          'money': {
            'width': Math.abs(delta.money.if_yes),
            'background-color': delta.money.if_yes > 0 ? 'green' :'rgba(255, 96, 0, 1)',
          },
          'energy': {
            'width': Math.abs(delta.energy.if_yes),
            'background-color': delta.energy.if_yes > 0 ? 'green' :'rgba(255, 96, 0, 1)',
          },
          'some_stuff': {
            'width': Math.abs(delta.some_stuff.if_yes),
            'background-color': delta.some_stuff.if_yes > 0 ? 'green' :'rgba(255, 96, 0, 1)',
          },
        });
      }
    } else if (prevPositionX >= 0 && newX < 0) {
      if (!hasLoggedNegative) {
        console.log('no');
        setHasLoggedNegative(true);
        setHasLoggedPositive(false);
        setWidthCircle({
          'money': {
            'width': Math.abs(delta.money.if_no),
            'background-color': delta.money.if_no > 0 ? 'green' :'rgba(255, 96, 0, 1)',
          },
          'energy': {
            'width': Math.abs(delta.energy.if_no),
            'background-color': delta.energy.if_no > 0 ? 'green' :'rgba(255, 96, 0, 1)',
          },
          'some_stuff': {
            'width': Math.abs(delta.some_stuff.if_no),
            'background-color': delta.some_stuff.if_no > 0 ? 'green' :'rgba(255, 96, 0, 1)',
          } ,
        });
      }
    }
  };
  
  function fetchHandler(boolStr) {
    ServerConnect.nextScreen({
      "cart_id": currentCard.card_id,
      "session_id": sessionId,
      "new_stats": {
        "money": currentStats.money + currentCard[boolStr].money <= 0 ? 0 : currentStats.money + currentCard[boolStr].money >= fullStats.money ? fullStats.money : currentStats.money + currentCard[boolStr].money,
        "energy": currentStats.energy + currentCard[boolStr].energy <= 0 ? 0 : currentStats.energy + currentCard[boolStr].energy >= fullStats.energy ? fullStats.energy : currentStats.energy + currentCard[boolStr].energy,
        "some_stuff": currentStats.some_stuff + currentCard[boolStr].some_stuff <= 0 ? 0 : currentStats.some_stuff + currentCard[boolStr].some_stuff >= fullStats.some_stuff ? fullStats.some_stuff : currentStats.some_stuff + currentCard[boolStr].some_stuff,
      }
    })
      .then((data) => {
        if (data.details) {
          if (data.details === 'User Game not exists') {
            ServerConnect.startGame({
              'clear_start': false,
              'session_id': localStorage.getItem('session_id'),
            })
              .then((data) => {
                console.log(data.data);
                localStorage.setItem('energy', false);
                localStorage.setItem('money', false);
                localStorage.setItem('reputation', false);
                localStorage.setItem('final', false);
                localStorage.setItem('pseudofinal', false);
                dispatch(setStartData(data.data));
                dispatch(setCardsLength(data.data.user_cards.length));
                dispatch(setCurrentIndex(0));
                dispatch(setActivePanel('main'));
              })
              .catch(err => console.log(err));
          }
          else if (data.details === "User Session not exists") {
            dispatch(setActivePanel('loading'));
          }
        }
        else {
          if (data.data.cur_stats.reach_try_limit) {
            dispatch(setActivePanel('pseudofinal'));
            localStorage.setItem('energy', false);
            localStorage.setItem('money', false);
            localStorage.setItem('reputation', false);
            localStorage.setItem('final', false);
            localStorage.setItem('pseudofinal', true);
          }
          else {
            if (data.data.cur_stats.game_ended && (currentStats.energy + currentCard[boolStr].energy > 0 && currentStats.money + currentCard[boolStr].money > 0 && currentStats.some_stuff + currentCard[boolStr].some_stuff > 0)) {
              dispatch(setActivePanel('final'));
              localStorage.setItem('energy', false);
              localStorage.setItem('money', false);
              localStorage.setItem('reputation', false);
              localStorage.setItem('final', true);
              localStorage.setItem('pseudofinal', false);
            }
            else {
              setCurrentStats(prevState => (
                {
                  ...prevState,
                  'money': currentStats.money + currentCard[boolStr].money <= 0 ? 0 : currentStats.money + currentCard[boolStr].money >= fullStats.money ? fullStats.money : prevState.money + currentCard[boolStr].money,
                  'energy': currentStats.energy + currentCard[boolStr].energy <= 0 ? 0 : currentStats.energy + currentCard[boolStr].energy >= fullStats.energy ? fullStats.energy : prevState.energy + currentCard[boolStr].energy,
                  "some_stuff": currentStats.some_stuff + currentCard[boolStr].some_stuff <= 0 ? 0 : currentStats.some_stuff + currentCard[boolStr].some_stuff >= fullStats.some_stuff ? fullStats.some_stuff : prevState.some_stuff + currentCard[boolStr].some_stuff,
                }
              ));
              
              setIsHidden(true);
              setTimeout(() => {
                const nextCardId = currentCardIndex + 1;
                // const nextCardId = position.x > 0 ? currentCard.rightAnswer.nextCardId : currentCard.leftAnswer.nextCardId;
                setNextCard(nextCardId);
                setFlipClass(`flip-${position.x > 0 ? 'left' : 'right'}`);
                setTimeout(() => {
                  onCardChange(nextCardId);
                  setFlipClass('');
                  setPosition({ x: 0, y: 0 });
                  setRotateY(0);
                  setRotateZ(0);
                  targetPositionRef.current = { x: 0, y: 0 };
                  setIsHidden(false);
                }, 250);
              }, 600); 
            }
          }
        }
      })
      .catch((err) => console.log(err));
  }

  const handleStop = () => {
    // if (!currentCard) return;
    if (Math.abs(targetPositionRef.current.x) >= 50) {
      setPrevPositionX(0); // Сбросить prevPositionX в 0
      setHasLoggedPositive(false); // Сбросить hasLoggedPositive
      setHasLoggedNegative(false); // Сбросить hasLoggedNegative
      if (position.x > 0) {
        fetchHandler("if_yes");
        setWidthCircle({
          'money': {
            'width': 0,
            'background-color': delta.money.if_yes > 0 ? 'green' :'rgba(255, 96, 0, 1)',
          },
          'energy': {
            'width': 0,
            'background-color': delta.energy.if_yes > 0 ? 'green' :'rgba(255, 96, 0, 1)',
          },
          'some_stuff': {
            'width': 0,
            'background-color': delta.some_stuff.if_yes > 0 ? 'green' :'rgba(255, 96, 0, 1)',
          },
        });
      }
      else {
        fetchHandler("if_no");
        setWidthCircle({
          'money': {
            'width': 0,
            'background-color': delta.money.if_yes > 0 ? 'green' :'rgba(255, 96, 0, 1)',
          },
          'energy': {
            'width': 0,
            'background-color': delta.energy.if_yes > 0 ? 'green' :'rgba(255, 96, 0, 1)',
          },
          'some_stuff': {
            'width': 0,
            'background-color': delta.some_stuff.if_yes > 0 ? 'green' :'rgba(255, 96, 0, 1)',
          },
        });
      }
    } else {
      targetPositionRef.current = { x: 0, y: 0 };
      setPosition({ x: 0, y: 0 });
      setRotateY(0);
      setRotateZ(0);
      setWidthCircle({
        'money': {
            'width': 0,
            'background-color': delta.money.if_yes > 0 ? 'green' :'rgba(255, 96, 0, 1)',
          },
          'energy': {
            'width': 0,
            'background-color': delta.energy.if_yes > 0 ? 'green' :'rgba(255, 96, 0, 1)',
          },
          'some_stuff': {
            'width': 0,
            'background-color': delta.some_stuff.if_yes > 0 ? 'green' :'rgba(255, 96, 0, 1)',
          },
      });
      setPrevPositionX(0); // Сбросить prevPositionX в 0
      setHasLoggedPositive(false); // Сбросить hasLoggedPositive
      setHasLoggedNegative(false); // Сбросить hasLoggedNegative
    }
  };

  return (
    <>
      <div className={`static-card ${flipClass}`} style={{ backgroundImage: `url(${backSideImage})` }}></div> {/* Рубашка карты */}
      {nextCardData && flipClass && (
        <div className={`static-card next-card`} style={{ backgroundImage: `url(${'https://reignsconst.despbots.ru' + nextCardData.img_url})` }}></div>
      )}
      {currentCard && <DraggableCore nodeRef={draggableRef} onDrag={handleDrag} onStop={handleStop}>
          <div
            ref={draggableRef}
            className={`card-wrapper ${animationClass} ${isHidden ? 'hidden' : ''}`}
            style={{
              transform: `translateX(${isHidden ? (position.x > 0 ? 20 : -20) : position.x}px) rotateY(${isHidden ? 0 : rotateY}deg) rotateZ(${isHidden ? 0 : rotateZ}deg) ${isHidden ? 'translateY(500px)' : ''}`,
              opacity: isHidden ? 0 : 1,
            }}
          >
            <div className="card" style={{ backgroundImage: `url(${'https://reignsconst.despbots.ru' + currentCard.img_url})` }}>
              <div
                className="overlay"
                style={{
                  opacity: Math.abs(position.x) / 100 - 0.05,
                }}
              >
                <div
                  className='text-answer'
                  style={{
                    opacity: 1,
                  }}>
                  {position.x > 0 ? "Да" : 'Нет'}
                </div>
              </div>
            </div>
          </div>
        </DraggableCore>}
    </>
  );
};

export default DraggableCard;
import React, { useEffect, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePanel, setSessionId, setStartData, setCardsLength, setCurrentIndex } from '../../store/mainReducer';
import ServerConnect from '../../service';
import checkboxDisable from '../../../public/assets/images/static/checkbox-disable.svg';
import checkboxEnable from '../../../public/assets/images/static/checkbox-enable.svg';

const OnBoardingFirst = () => {
  const dispatch = useDispatch();
  const crfToken = useSelector(state => state.main.crfToken);
  const [classPage, setClassPage] = useState('onboarding1');
  const [buttonClass, setButtonClass] = useState('onboarding1__button');
  const [validMail, setValidMail] = useState(false);
  const [popupClass, setPopupClass] = useState('invalid-popup');
  const [checkboxValue, setCheckboxValue] = useState({
    'pers': false,
    'mail': false,
  })
  const [inputsValue, setInputsValue] = useState({
    fio: '',
    company: '',
    email: ''
  });
  const checkEmailMask = email => {
    if (/.+@.+\.[A-Za-z]+$/.test(email)) {
      console.log('valid mail')
      setValidMail(true);
    } else {
      console.log('invalid mail')
      setValidMail(false);
    }
  };

  function buttonHandler() {
    if (validMail) {
      ServerConnect.startGame({
        'full_name': inputsValue.fio,
        'company_name': inputsValue.company,
        'company_email': inputsValue.email,
        "mailing_agreed": checkboxValue.mail,
        'clear_start': true,
      })
        .then((data) => {
          console.log(data.data);
          dispatch(setStartData(data.data));
          dispatch(setCardsLength(data.data.user_cards.length));
          dispatch(setCurrentIndex(0));
          dispatch(setSessionId(data.data.session_id))
          if (data.data.session_id) {
            localStorage.setItem("session_id", data.data.session_id)
          }
          setClassPage("onboarding1");
          setTimeout(() => {
            dispatch(setActivePanel('training'));
          }, 500)
        })
        .catch(err => console.log(err))
    }
    else {
      setPopupClass('invalid-popup invalid-popup_active');
    }
  }
  useEffect(() => {
    setTimeout(() => {
      setClassPage('onboarding1 onboarding1_active');
    }, 100)
  }, []);
  useEffect(() => {
    if (inputsValue.fio.trim() != '' && inputsValue.company.trim() != '' && inputsValue.email.trim() != '' && checkboxValue['pers'] && checkboxValue['mail']) {
      setButtonClass("onboarding1__button onboarding1__button_active");
    }
    else {
      setButtonClass("onboarding1__button");
    }
  }, [inputsValue, checkboxValue])
  return (
    <div className={`container ${classPage}`}>
      <div className='onboarding1__content'>
        <div onClick={(evt) => {
          if (evt.target.className === "invalid-popup invalid-popup_active") {
            setPopupClass('invalid-popup');
          }
        }} className={popupClass}>
          <div className='invalid-popup__content'>
            <p className='invalid-popup__title'>
              Некорректный<br />
              адрес
            </p>
            <p className="invalid-popup__subtitle">
              Введите адрес вашей<br />
              рабочей почты
            </p>
            <button onClick={() => {setPopupClass('invalid-popup')}} className='invalid-popup__button' type='button'>
              Принять
            </button>
          </div>
        </div>
        <img className='onboarding1__logo' src="assets/images/loading-page-logo.svg" alt="" />
        <div className='onboarding1__main'>
          <p className='onboarding1__title'>
            Оставьте контакты, чтобы получить приз за&nbsp;победу
          </p>
          <p className='onboarding1__label'>
            Имя
          </p>
          <input value={inputsValue.fio} onChange={(e) => setInputsValue({...inputsValue, fio: e.target.value})} className='onboarding1__input' type="text" placeholder='Начните вводить...'/>
          <p className='onboarding1__label'>
            Компания
          </p>
          <input value={inputsValue.company} onChange={(e) => setInputsValue({...inputsValue, company: e.target.value})} className='onboarding1__input' type="text" placeholder='Начните вводить...'/>
          <p className='onboarding1__label'>
            Рабочая почта
          </p>
          <input value={inputsValue.email} onChange={(e) => {
            setInputsValue({...inputsValue, email: e.target.value});
            checkEmailMask(e.target.value);
            }} className='onboarding1__input' type="email" placeholder='Начните вводить...'/>
          <div onClick={() => {
            setCheckboxValue((prevState) => ({
              ...prevState,
              'pers': !prevState["pers"],
            }));
          }} className='onboarding1__checkbox onboarding1__checkbox_pers'>
            <img src={checkboxValue.pers ? checkboxEnable : checkboxDisable} className='onboarding1__checkbox-img' alt="" />
            <p className='onboarding1__checkbox-text'>
              Согласен с <a className='onboarding1__link' href='https://www.ucmsgroup.ru/ru/policy-on-processing-and-protection-of-personal-data/ ' target='_blank'>политикой</a> и <a className='onboarding1__link' href='https://www.ucmsgroup.ru/ru/terms-of-processing-of-personal-data/' target='_blank'>условиями обработки персональных данных</a>
            </p>
          </div>
          <div onClick={() => {
            setCheckboxValue((prevState) => ({
              ...prevState,
              'mail': !prevState["mail"],
            }));
          }} className='onboarding1__checkbox'>
            <img src={checkboxValue.mail ? checkboxEnable : checkboxDisable} className='onboarding1__checkbox-img' alt="" />
            <p className='onboarding1__checkbox-text'>
              Подписаться на рассылку (пишем раз в пару месяцев)
            </p>
          </div>
          <button onClick={buttonHandler} className={buttonClass} type='button'>
            <p className='onboarding1__button-text'>Продолжить</p>
            <div className='onboarding1__button-help'></div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnBoardingFirst;
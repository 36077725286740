import React, { useEffect, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePanel, setStartData, setCardsLength, setCurrentIndex } from '../../store/mainReducer';
import ServerConnect from '../../service';

const Final = () => {
  const dispatch = useDispatch();
  const [classPage, setClassPage] = useState('final');
  const sessionId = useSelector(state => state.main.sessionId);
  useEffect(() => {
    setTimeout(() => {
      setClassPage('final final_active');
    }, 100)
  }, []);
  function buttonHandler() {
    ServerConnect.startGame({
      'clear_start': false,
      'session_id': sessionId,
      'mailing_agreed': false,
    })
      .then((data) => {
        console.log(data.data);
        localStorage.setItem('energy', false);
        localStorage.setItem('money', false);
        localStorage.setItem('reputation', false);
        localStorage.setItem('final', false);
        localStorage.setItem('pseudofinal', false);
        dispatch(setStartData(data.data));
        dispatch(setCardsLength(data.data.user_cards.length));
        dispatch(setCurrentIndex(0));
        setClassPage('final');
        setTimeout(() => {
          dispatch(setActivePanel("main"));
        }, 500)
      })
      .catch(err => console.log(err));
  }
  return (
    <div className={`container ${classPage}`}>
      <div className='final__content'>
        <p className='final__title'>
          Поздравляем! Вы&nbsp;почти канатоходец&nbsp;&mdash; мастерски умеете удерживать баланс между задачами.
        </p>
        <div className='final__info'>
          Покажите результат 
          на&nbsp;стенде UCMS GROUP
        </div>
        <img className='final__img' src="assets/images/final-img.png" alt="" />
        <div className='final__buttons'>
          <button type='button' className='final__info-btn'>
            <a className='final__info-btn-link' href="https://www.ucmsgroup.ru/" target='_blank'></a>
            Узнать больше про UCMS Group
          </button>
          <button onClick={buttonHandler} className='final__button' type='button'>
            <img className='final__button-img' src="assets/images/recycle-orange.svg" alt="" />
            <p className='final__button-text'>
              Перезапустить игру
            </p>
          </button>
          <a className='final__sticker' href="" target='_blank'>
            Стикерпак в подарок
          </a>
        </div>
      </div>
    </div>
  );
};

export default Final;
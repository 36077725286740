import React, { useState, useEffect, useRef } from 'react';
import { DraggableCore } from 'react-draggable';
import './style.css';
import ServerConnect from '../../service';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePanel } from '../../store/mainReducer';

const DraggableTraining = ({ cards, backSideImage, tutorialActive, setTutorialActive, setIsLeft }) => {
  const dispatch = useDispatch();
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [rotateY, setRotateY] = useState(0);
  const [rotateZ, setRotateZ] = useState(0);
  const [isHidden, setIsHidden] = useState(false);
  const [animationClass, setAnimationClass] = useState('');
  const [flipClass, setFlipClass] = useState('');
  const [nextCard, setNextCard] = useState(null);
  const targetPositionRef = useRef({ x: 0, y: 0 });
  const timeoutsRef = useRef([]);
  const draggableRef = useRef(null);

  const currentCard = cards[0];

  const animationDurationX = 0.5;
  const animationDurationZ = 0.15;
  const epsilon = 0.01;

  const clearAllTimeouts = () => {
    timeoutsRef.current.forEach(clearTimeout);
    timeoutsRef.current = [];
  };

  useEffect(() => {
    if (!tutorialActive) return;

    const runTutorialAnimation = () => {
      setAnimationClass('animate-left');
      console.log('left')
      setIsLeft(true);
      targetPositionRef.current = { x: -80, y: 0 };
      const timeout1 = setTimeout(() => {
        setAnimationClass('animate-right');
        console.log('right')
        setIsLeft(false);
        targetPositionRef.current = { x: 80, y: 0 };
        const timeout2 = setTimeout(() => {
          setAnimationClass('animate-center');
          targetPositionRef.current = { x: 0, y: 0 };
          // const timeout3 = setTimeout(runTutorialAnimation, 5000);
          const timeout3 = setTimeout(runTutorialAnimation, 500);
          timeoutsRef.current.push(timeout3);
        }, 2000);
        timeoutsRef.current.push(timeout2);
      }, 2000);
      timeoutsRef.current.push(timeout1);
    };

    const startTimeout = setTimeout(runTutorialAnimation, 500);
    timeoutsRef.current.push(startTimeout);

    return () => {
      clearAllTimeouts();
      setAnimationClass('');
    };
  }, [tutorialActive]);

  useEffect(() => {

    let animationFrameId;
    const animate = () => {
      setPosition(prevPosition => {
        const targetPosition = targetPositionRef.current;
        const dx = targetPosition.x - prevPosition.x;
        if (Math.abs(dx) < epsilon) return prevPosition;
        const newX = prevPosition.x + dx * animationDurationX;
        const newRotateY = newX / 10;
        setRotateY(newRotateY);
        return { x: newX, y: 0 };
      });

      setRotateZ(prevRotateZ => {
        const targetRotateZ = position.x / 20;
        const dz = targetRotateZ - prevRotateZ;
        if (Math.abs(dz) < epsilon) return prevRotateZ;
        return prevRotateZ + dz * animationDurationZ;
      });

      animationFrameId = requestAnimationFrame(animate);
    };
    animationFrameId = requestAnimationFrame(animate);
    return () => cancelAnimationFrame(animationFrameId);
  }, [position.x, animationDurationX, animationDurationZ, currentCard]);


  const handleDrag = (e, data) => {
    if (!currentCard) return;
    clearAllTimeouts();
    setAnimationClass('');
    setTutorialActive(false);
    const newX = Math.min(100, Math.max(-100, targetPositionRef.current.x + data.deltaX));
    targetPositionRef.current = { x: newX, y: 0 };
  };
  

  const handleStop = () => {
    // if (!currentCard) return;
    if (Math.abs(targetPositionRef.current.x) >= 50) {
      setIsHidden(true);
          setTimeout(() => {
            const nextCardId = currentCardIndex + 1;
            // const nextCardId = position.x > 0 ? currentCard.rightAnswer.nextCardId : currentCard.leftAnswer.nextCardId;
            setNextCard(nextCardId);
            setFlipClass(`flip-${position.x > 0 ? 'left' : 'right'}`);
            setTimeout(() => {
              onCardChange(nextCardId);
              setFlipClass('');
              setPosition({ x: 0, y: 0 });
              setRotateY(0);
              setRotateZ(0);
              targetPositionRef.current = { x: 0, y: 0 };
              setIsHidden(false);
            }, 250);
          }, 500); 
    } else {
      targetPositionRef.current = { x: 0, y: 0 };
      setPosition({ x: 0, y: 0 });
      setRotateY(0);
      setRotateZ(0);
    }
  };

  return (
    <>
      <div className={`static-card ${flipClass}`} style={{ backgroundImage: `url(${backSideImage})` }}></div> {/* Рубашка карты */}
      {/* {nextCardData && flipClass && (
        <div className={`static-card next-card`} style={{ backgroundImage: `url(${'https://reignsconst.despbots.ru' + nextCardData.img_url})` }}></div>
      )} */}
      {currentCard && <DraggableCore nodeRef={draggableRef}>
          <div
            ref={draggableRef}
            className={`card-wrapper ${animationClass} ${isHidden ? 'hidden' : ''}`}
            style={{
              transform: `translateX(${isHidden ? (position.x > 0 ? 20 : -20) : position.x}px) rotateY(${isHidden ? 0 : rotateY}deg) rotateZ(${isHidden ? 0 : rotateZ}deg) ${isHidden ? 'translateY(500px)' : ''}`,
              opacity: isHidden ? 0 : 1,
            }}
          >
            <div className="card" style={{ backgroundImage: `url(${cards[0]})` }}>
              <div
                className="overlay"
                style={{
                  opacity: Math.abs(position.x) / 100 - 0.05,
                }}
              >
                <div
                  className='text-answer'
                  style={{
                    opacity: 1,
                  }}>
                  {position.x > 0 ? "Да" : 'Нет'}
                </div>
              </div>
            </div>
          </div>
        </DraggableCore>}
    </>
  );
};

export default DraggableTraining;
import React, { useEffect, useRef, useState } from 'react';
import './style.css';
import { useDispatch, useSelector } from 'react-redux';
import { setActivePanel, setStartData, setCardsLength, setCurrentIndex } from '../../store/mainReducer';
import ServerConnect from '../../service';

const Energy = () => {
  const dispatch = useDispatch();
  const sessionId = useSelector(state => state.main.sessionId);
  const [classPage, setClassPage] = useState('energy');
  useEffect(() => {
    setTimeout(() => {
      setClassPage('energy energy_active');
    }, 100)
  }, []);
  const bottomRef = useRef(null);

  const scrollToBottom = () => {
    bottomRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 100)
  }, [])
  function buttonHandler() {
    ServerConnect.startGame({
      'clear_start': false,
      'session_id': sessionId,
      'mailing_agreed': false,
    })
      .then((data) => {
        console.log(data.data);
        localStorage.setItem('energy', false);
        localStorage.setItem('money', false);
        localStorage.setItem('reputation', false);
        localStorage.setItem('final', false);
        localStorage.setItem('pseudofinal', false);
        dispatch(setStartData(data.data));
        dispatch(setCardsLength(data.data.user_cards.length));
        dispatch(setCurrentIndex(0));
        setClassPage('energy');
        setTimeout(() => {
          dispatch(setActivePanel("main"));
        }, 500);
      })
      .catch(err => console.log(err));
  }
  return (
    <div className={`container ${classPage}`}>
      <div className='energy__content'>
        <img className='energy__img' src="assets/images/energy-big.png" alt="" />
        <p className='energy__title'>
          Всё, организм 
          отказывается делать 
          хоть что-то.
        </p>
        <p className='energy__subtitle'>
          Но&nbsp;без паники&nbsp;&mdash; немного отдыха, 
          и&nbsp;снова за&nbsp;дело. Рекомендуем перестать пытаться вывезти всё в&nbsp;одиночку и&nbsp;учиться делегировать задачи.
        </p>
        <button type='button' className='energy__info-btn'>
          <a className='energy__info-btn-link' href="https://www.ucmsgroup.ru/" target='_blank'></a>
          Узнать больше про UCMS Group
        </button>
        <button onClick={buttonHandler} className='energy__button' type='button'>
          <img className='energy__button-img' src="assets/images/recycle.svg" alt="" />
          <p className='energy__button-text'>
            Перезапустить игру
          </p>
        </button>
        <a ref={bottomRef} className='energy__sticker' href="" target='_blank'>
          Стикерпак в подарок
        </a>
      </div>
    </div>
  );
};

export default Energy;